import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const subscriptionCreate = createAsyncThunk(
  "subscriptionCreate",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/v3/create`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const subscriptionCreateSlice = createSlice({
  name: "subscriptionCreate",
  initialState: {
    isLoading: false,
    subscriptionCreateData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [subscriptionCreate.pending]: (state) => {
      state.isLoading = true;
    },
    [subscriptionCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionCreateData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [subscriptionCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default subscriptionCreateSlice.reducer;
