function base64ToStringWithKey(base64) {
  const secretKey = "wkr9zNhsyuLsTe0UBNhLrNiH2q6sFTfdfea"
  const key = new TextEncoder().encode(secretKey);
  const encryptedData = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
  const decryptedData = encryptedData.map(
    (byte, index) => byte ^ key[index % key.length]
  );
  return new TextDecoder().decode(decryptedData);
}
export const API_KEY = process.env.REACT_APP_API_KEY;
export const stripeLoadScript = process.env.REACT_APP_STRIPE_LOAD_SCRIPT;
export const googleAPIKey = base64ToStringWithKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
export const gpay_merchantId = base64ToStringWithKey(process.env.REACT_APP_GOOGLE_PAY_MERCHANT);
export const spreedly_merchantId = base64ToStringWithKey(process.env.REACT_APP_SPREEDLY_MERCHANT);
export const applePay_merchantId = base64ToStringWithKey(process.env.REACT_APP_APPLE_PAY_MERCHANT);
export const google_client_id = base64ToStringWithKey(  process.env.REACT_APP_GOOGLE_CLIENT_ID);
