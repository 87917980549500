import React, {
  Suspense,
  createContext,
  lazy,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./language";
import "./scss/style.scss";
import { google_client_id } from "./environment";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Cookies, useCookies } from "react-cookie";

const AppLayout = lazy(() => import("./layouts"));
const AppProvider = lazy(() => import("./provider"));
const AppRoutes = lazy(() => import("./routes"));
const AppContextInput = createContext();

export const useAppContextInput = () => useContext(AppContextInput);
function App() {
  const inputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const [plan, setPlan] = useState("trial");
  const handleInputFocus = (props) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPlan(props);
    if (window.screen.width > 767) {
      inputRef.current.focus();
    } else {
      mobileInputRef.current.focus();
    }
  };
  // Logout function
  const cookies = new Cookies();
  // eslint-disable-next-line
  const [token, setToken, removeToken] = useCookies(["token"]);
  // eslint-disable-next-line
  const [roles, setRole, removeRole] = useCookies(["role"]);
  const handleLogout = () => {
    removeToken(["token"]);
    removeRole(["role"]);
    const cookieKeys = Array.isArray(cookies.getAll())
      ? cookies.getAll()
      : Object.keys(cookies.getAll());
    cookieKeys.forEach((key) => {
      if (key !== "currency" && key !== "lang" && key !== "langlogo") {
        cookies.remove(key, { path: "/" });
      }
    });
    window.location.reload("/");
  };
  return (
    <Suspense>
      <GoogleOAuthProvider clientId={google_client_id}>
        <AppProvider>
          <AppContextInput.Provider
            value={{
              inputRef,
              mobileInputRef,
              handleInputFocus,
              plan,
              handleLogout,
            }}
          >
            <Suspense>
              <AppLayout>
                <Suspense>
                  <AppRoutes />
                </Suspense>
              </AppLayout>
            </Suspense>
          </AppContextInput.Provider>
        </AppProvider>
      </GoogleOAuthProvider>
    </Suspense>
  );
}

export default App;
