import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";

export const addEditCurrency = createAsyncThunk(
  "addEditCurrency",
  async (data) => {
    try {
      const response = await AxiosInstance.post(`/pricing/upsert`, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const addEditCurrencySlice = createSlice({
  name: "addEditCurrency",
  initialState: {
    isLoading: false,
    addEditCurrencyData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [addEditCurrency.pending]: (state) => {
      state.isLoading = true;
    },
    [addEditCurrency.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.addEditCurrencyData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [addEditCurrency.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default addEditCurrencySlice.reducer;
